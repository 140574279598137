import React from 'react';

function CongressionalOutreachFooter() {
  return (
    <div style={{ display: 'flex', marginTop: '60px' }}>
      <div style={{ flex: 1, textAlign: 'right' }}>
        <p>Phone: (555) 123-4567</p>
      </div>
      <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <p>|</p>
      </div>
      <div style={{ flex: 1, textAlign: 'left' }}>
        <p>Email: outreach@example.com</p>
      </div>
    </div>
  );
}

export default CongressionalOutreachFooter;
