import React, { FunctionComponent, useEffect, useState } from 'react';
import withContext from '../../../ContextAPI/Context_HOC';
import CongressionalOutreachHeader from '../CongressionalOutreachHeader';
import CongressionalOutreachFooter from '../CongressionalOutreachFooter';
import CongressionalOutreachSubpageProxy from './CongressionalOutreachSubpageProxy';
import NoMatch from '../../../Routers/NoMatch';
import { Col, Row } from 'react-flexbox-grid';
import { OpaCongressionalOutreachEnriched } from './Models/OpaCongressionalOutreachEnriched';
import CongressionalOutreachRichContent from './CongressionalOutreachRichContent';

const ConressionalOutreachSubpage: FunctionComponent<{
  match: { params: { id: string } };
  context: { congressionalOutreachSubpageProxy: CongressionalOutreachSubpageProxy };
}> = ({ match, context: { congressionalOutreachSubpageProxy } }) => {
  const urlSlug = match.params.id;
  const [loading, setLoading] = useState<boolean>(true);
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);
  const [article, setArticle] = useState<OpaCongressionalOutreachEnriched | null>(null);

  useEffect(() => {
    const opaSubscription = congressionalOutreachSubpageProxy.getOutreachSubpage(urlSlug).subscribe({
      next: (article) => {
        setArticle(article);
        setLoading(false);
      },
      error: (error) => {
        console.error(error);
        setErrorOccurred(true);
      },
    });
    return () => opaSubscription.unsubscribe();
  }, [urlSlug, congressionalOutreachSubpageProxy]);

  if (errorOccurred) {
    return <NoMatch />;
  }

  if (loading || !article) {
    return <p>Loading...</p>;
  }
  return (
    <>
      <CongressionalOutreachHeader subtitle={article.baseTitle.value} />
      <Row>
        <Col sm={12}>
          <CongressionalOutreachRichContent baseBody={article.baseBody.value} modularContent={article.linkedItems} />
        </Col>
      </Row>
      <CongressionalOutreachFooter />
    </>
  );
};
export default withContext(ConressionalOutreachSubpage);
