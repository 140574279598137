import React, { FunctionComponent } from 'react';
import CongressionalOutreachSectionAccordion from './CongressionalOutreachSectionAccordion';
import { IContentItem, IContentItemsContainer } from '@kentico/kontent-delivery';
import ModularContentParser from '../../Utilities/ModularContentParser';
import parse from 'html-react-parser';

interface CongressionalOutreachRichContentProps {
  baseBody: string;
  modularContent: IContentItemsContainer<IContentItem>;
}

const CongressionalOutreachRichContent: FunctionComponent<CongressionalOutreachRichContentProps> = ({
  baseBody,
  modularContent,
}) => {
  const parts = ModularContentParser(baseBody, modularContent);

  return (
    <>
      {parts.map((part, index) => {
        var content = parse(part);

        if (index % 2 === 0) {
          // Regular text
          return <span key={`text-${index}`}>{content}</span>;
        } else {
          // Modular content replacement
          const item = modularContent ? modularContent[part] : null;
          return item ? (
            <CongressionalOutreachSectionAccordion key={`accordion-${index}`} opaContent={item} />
          ) : (
            <span key={`placeholder-${index}`}>{content}</span>
          );
        }
      })}
    </>
  );
};

export default CongressionalOutreachRichContent;
