import React from 'react';
import HeaderImage from '../../assets/img/opa-congressional-outreach-header.png';

function CongressionalOutreachHeader({ subtitle }) {
  return (
    <header
      style={{
        width: '100%',
        textAlign: 'center',
        padding: '20px 0',
        position: 'relative',
      }}
    >
      <img src={HeaderImage} alt="Congressional Outreach" style={{ maxWidth: '100%', height: 'auto', objectFit: 'cover' }} />
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: 'white',
          textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
          textAlign: 'center',
          padding: '10px',
          width: '90%',
          boxSizing: 'border-box',
        }}
      >
        <h1 style={{ fontSize: 'clamp(1.5em, 5vw, 2.5em)', margin: 0 }}>Congressional Outreach</h1>
        <p data-cy="base-title-in-header" style={{ fontSize: 'clamp(1em, 3vw, 1.2em)', margin: '10px 0 0' }}>
          {subtitle}
        </p>
      </div>
    </header>
  );
}

export default CongressionalOutreachHeader;
