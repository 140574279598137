import ContentProviderEndpoint from '../../../Content/ContentProviderEndpoint';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { OpaCongressionalOutreach } from '../../../Kentico/DigitalDesign/opa_congressional_outreach';
import { OpaCongressionalOutreachEnriched } from './Models/OpaCongressionalOutreachEnriched';

export default class CongressionalOutreachSubpageProxy {
  digitalDesignContent: ContentProviderEndpoint;

  constructor(digitalDesignContent: ContentProviderEndpoint) {
    this.digitalDesignContent = digitalDesignContent;
  }

  getOutreachSubpage(urlSlug: string): Observable<OpaCongressionalOutreachEnriched> {
    const newSubject = new ReplaySubject<OpaCongressionalOutreachEnriched>(1);
    this.digitalDesignContent
      .items<OpaCongressionalOutreachEnriched>()
      .type('opa_congressional_outreach')
      .equalsFilter('elements.url_slug', urlSlug)
      .orderByDescending('elements.baseDatepublished')
      .limitParameter(1)
      .includeTotalCountParameter()
      .toObservable()
      .pipe(
        map((response) => {
          if (response.pagination.totalCount && response.pagination.totalCount > 1) {
            throw new Error('More than one item found');
          }
          if (response.items.length !== 1) {
            throw new Error('Expected exactly one item but found ' + response.items.length);
          }
          const article = response.items[0];
          article.linkedItems = response.linkedItems;

          return article;
        })
      )
      .subscribe(newSubject);
    return newSubject;
  }

  getOutreachSubpageImages(): Observable<OpaCongressionalOutreach[]> {
    const newSubject = new ReplaySubject<OpaCongressionalOutreach[]>(1);
    this.digitalDesignContent
      .items<OpaCongressionalOutreach>()
      .type('opa_congressional_outreach')
      .orderByDescending('elements.baseDatepublished')
      .toObservable()
      .pipe(map((x) => x.items))
      .subscribe(newSubject);
    return newSubject;
  }
}
