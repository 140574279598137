import { TypeResolver } from '@kentico/kontent-delivery';
import { HomeCarousel } from './DigitalDesign/home_carousel';
import { Homeblock } from './DigitalDesign/homeblock';
import { NatconArticle } from './DigitalDesign/natcon_article';
import { NatconCarousel } from './DigitalDesign/natcon_carousel';
import { OpaCongressionalOutreach } from './DigitalDesign/opa_congressional_outreach';

export default [
  new TypeResolver('home_carousel', () => new HomeCarousel()),
  new TypeResolver('homeblock', () => new Homeblock()),
  new TypeResolver('natcon_article', () => new NatconArticle()),
  new TypeResolver('natcon_carousel', () => new NatconCarousel()),
  new TypeResolver('opa_congressional_outreach', () => new OpaCongressionalOutreach()),
];
