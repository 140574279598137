import React, { FunctionComponent, useEffect, useState } from 'react';
import CongressionalOutreachSubpageProxy from './CongressionalOutreach/CongressionalOutreachSubpageProxy';
import CongressionalOutreachImageCard from './CongressionalOutreachImageCard';
import withContext from '../../ContextAPI/Context_HOC';

const CongressionalOutreachImageGrid: FunctionComponent<{
  context: { congressionalOutreachSubpageProxy: CongressionalOutreachSubpageProxy };
}> = ({ context: { congressionalOutreachSubpageProxy } }) => {
  const [images, setImages] = useState<{ imageUrl: string | null; urlSlug: string; altText: string }[]>([]);

  useEffect(() => {
    const opaSubscription = congressionalOutreachSubpageProxy.getOutreachSubpageImages().subscribe((x) => {
      const imageUrls = x.map((article) => ({
        imageUrl: article.homepageLinkImage.value.length > 0 ? article.homepageLinkImage.value[0].url : null,
        urlSlug: article.urlSlug.value,
        altText: article.system.name,
      }));
      setImages(imageUrls);
    });

    return () => opaSubscription.unsubscribe();
  }, [congressionalOutreachSubpageProxy]);

  return (
    <main className="imageGridImageGrid">
      {images.map((image, index) => (
        <CongressionalOutreachImageCard
          key={index}
          imageUrl={image.imageUrl}
          linkHref={image.urlSlug}
          imageAltText={image.altText}
        />
      ))}
    </main>
  );
};

export default withContext(CongressionalOutreachImageGrid);
