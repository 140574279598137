import { IContentItem, IContentItemsContainer } from '@kentico/kontent-delivery';

export default function (baseBody: string, modularContent: IContentItemsContainer<IContentItem>) {
  const regex = /<object[^>]+data-codename="(.*?)"[^>]*>(?:<\/object>)?/g;
  const parts = baseBody.split(regex);

  if (modularContent) {
    const modularContentIds = Object.keys(modularContent);

    //validate if every modular item have it's placeholder
    modularContentIds.forEach((id) => {
      if (!baseBody.includes(id)) {
        console.error(`Placeholder is missing in content body for modular item with id: ${id}`);
      }
    });
  }

  //validate if every section present in body, also present in modularContent
  parts.forEach((value, index) => {
    if (index % 2 !== 0) {
      const modularItem = modularContent ? modularContent[value] : null;
      if (!modularItem) {
        console.error(`Modular content not found for: ${value}`);
      }
    }
  });

  return parts;
}
