import React, { FunctionComponent } from 'react';
import CongressionalOutreachHeader from './CongressionalOutreachHeader';
import CongressionalOutreachFooter from './CongressionalOutreachFooter';
import CongressionalOutreachImageGrid from './CongressionalOutreachImageGrid';
import withContext from '../../ContextAPI/Context_HOC';

const CongressionalOutreach: FunctionComponent = () => {
  return (
    <div>
      <CongressionalOutreachHeader subtitle="Connecting Leaders and Communities" />
      <CongressionalOutreachImageGrid />
      <CongressionalOutreachFooter />
    </div>
  );
};

export default withContext(CongressionalOutreach);
