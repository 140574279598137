import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by Raaz Khoshnood Manually
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class OpaCongressionalOutreach extends ContentItem {
  public baseTitle: Elements.TextElement;
  public baseBody: Elements.RichTextElement;
  public homepageLinkImage: Elements.AssetsElement;
  public urlSlug: Elements.UrlSlugElement;
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === 'base__title') {
          return 'baseTitle';
        }
        if (elementName === 'url_slug') {
          return 'urlSlug';
        }
        if (elementName === 'homepage_link_image') {
          return 'homepageLinkImage';
        }
        if (elementName === 'base__body') {
          return 'baseBody';
        }
        return elementName;
      },
    });
  }
}
