import React, { useState } from 'react';

interface Video {
  id: string;
  title: string;
}

interface VideoSwitcherProps {
  videos: Video[];
}

const VideoSwitcher: React.FC<VideoSwitcherProps> = ({ videos }) => {
  const [videoSrc, setVideoSrc] = useState(`https://player.vimeo.com/video/${videos[0].id}?h=2089eeef16`);

  const switchVideo = (videoId: string) => {
    setVideoSrc(`https://player.vimeo.com/video/${videoId}?h=2089eeef16`);
  };

  return (
    <div className="boxStyle">
      <iframe
        id="videoPlayer"
        src={videoSrc}
        width="100%"
        height="310"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        title="Vimeo video player"
      />
      <div className="containerStyleFund">
        {videos.map((video, index) => (
          <div key={index} className="containerStyleFifty">
            {videoSrc === `https://player.vimeo.com/video/${video.id}?h=2089eeef16` ? (
              <div>{video.title}</div>
            ) : (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  switchVideo(video.id);
                }}
              >
                {video.title}
              </a>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideoSwitcher;
