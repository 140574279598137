import { IContentItem } from '@kentico/kontent-delivery';
import React, { FunctionComponent } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ExpandMore } from '@material-ui/icons';
import parse from 'html-react-parser';

const CongressionalOutreachSectionAccordion: FunctionComponent<{ opaContent: IContentItem }> = ({ opaContent }) => {
  return (
    <div data-cy="opa-accordion">
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <h4 className="u-position-relative">{opaContent.section_title.value}</h4>
        </AccordionSummary>
        <AccordionDetails>{parse(opaContent.section_rich_text.value)}</AccordionDetails>
      </Accordion>
    </div>
  );
};

export default CongressionalOutreachSectionAccordion;
