import React, { FunctionComponent } from 'react';
import FirstCard from '../../assets/img/opa-card-backup.png';
import { Link } from 'react-router-dom';

const ImageCard: FunctionComponent<{
  imageUrl: string | null;
  linkHref: string;
  imageAltText: string;
}> = ({ imageUrl, linkHref, imageAltText }) => {
  return (
    <Link className="imageGridLink" to={`/resources/public-affairs/congressional-outreach/${linkHref}`}>
      <img src={imageUrl || FirstCard} alt={imageAltText} className="imageGridLinkCard" />
      {!imageUrl && (
        <div className="imageGridImage">
          <p>{imageAltText}</p>
        </div>
      )}
    </Link>
  );
};

export default ImageCard;
