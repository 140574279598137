import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Link } from 'react-router-dom';

const PhysicalAddress = (props) => {
  const details = props.details;

  return (
    <div>
      <Row>
        <h4 className="medium-left-padding w-auto">Current Residential Address</h4>
        <div>
          <Col sm={6}>
            <Link to="/myprofile/edit-residential-address">Change My Residential Address</Link>
          </Col>
        </div>
      </Row>
      <Row>
        <Col sm={12}>
          <p className="faded-text small-text">In Care Of</p>
          <p>
            <strong>{details.physicalAddressCareOf.value}</strong>
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <p className="faded-text small-text">Street Address </p>
          <p>
            <strong>{details.physicalAddressLine1.value}</strong>
          </p>
        </Col>
        {details.physicalAddressLine2.value ? (
          <Col sm={4}>
            <p className="faded-text small-text">Address Line two</p>
            <p>
              <strong>{details.physicalAddressLine2.value}</strong>
            </p>
          </Col>
        ) : null}
        {details.physicalAddressLine3.value ? (
          <Col sm={4}>
            <p className="faded-text small-text">Address Line three</p>
            <p>
              <strong>{details.physicalAddressLine3.value}</strong>
            </p>
          </Col>
        ) : null}
      </Row>
      <Row>
        <Col sm={4}>
          <p className="faded-text small-text">City </p>
          <p>
            <strong>{details.physicalAddressCity.value}</strong>
          </p>
        </Col>
        <Col sm={4}>
          <p className="faded-text small-text">State </p>
          <p>
            <strong>{details.physicalAddressState.value}</strong>
          </p>
        </Col>
        <Col sm={4}>
          <p className="faded-text small-text">Zip Code </p>
          <p>
            <strong>{details.physicalAddressZipCode.value}</strong>
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default PhysicalAddress;
