import React from 'react';
import withContext from '../ContextAPI/Context_HOC';
import { withRouter } from 'react-router-dom';

function MainGlsa() {
  return (
    <div>
      <h1>Guidelines for LSAs</h1>
      <p>
        Originally produced in 1989, Guidelines for Local Spiritual Assemblies or GLSA, formerly known as{' '}
        <em>Developing Distinctive Bahá’í Communities: Guidelines for Local Spiritual Assemblies</em>, is a standard reference for
        authoritative guidance and national policies on facilitating the administration of community life by Local Spiritual
        Assemblies. The National Spiritual Assembly encourages all Local Assemblies to access and use this manual in preparation
        for and during Assembly meetings to clarify and deepen their understanding of relevant guidance necessary for their work.
      </p>
      <p>
        <em>Guidelines for Local Spiritual Assemblies</em> is available in electronic form only and is periodically updated.
        Please note that you have the National Assembly’s permission to make copies of portions of the manual for the use of your
        Local Assembly. Should you have any questions about the GLSA, please feel free to contact the Office of Community
        Administration by email at <a href="mailto:community@usbnc.org">community@usbnc.org</a> or by telephone at{' '}
        <a href="tel:847-733-3593">847-733-3593</a>.
      </p>
      <p>
        The latest edition of the GLSA (i.e., the February 2025 edition) aligns the organization and content of GLSA more closely
        with the framework for action in which the Bahá’í world is learning to operate during the Nine Year Plan. The chapters and
        major sections are organized such that the overarching purposes and principles related to particular topics, which are
        found in the Sacred Writings and the guidance of Shoghi Effendi and the Universal House of Justice, appear at the
        beginning. In this way, it is hoped that the relationships between these purposes and principles and the specific policies
        and procedures that stem from them can be more clearly seen. At present, you can download the entire February 2025 edition
        of the GLSA as a PDF document. An improved online version will be available in the future.
      </p>
      <br />
      <h4>
        <strong>Download: </strong>
        <a href="https://bahai-web.s3.us-east-1.amazonaws.com/forms/Assemblies%26Communities/Guidelines+Local+Spiritual+Assemblies-GLSA+Feb+2025+edition.pdf">
          Guidelines for Local Spiritual Assemblies
        </a>{' '}
        - <em>February 2025 edition</em>
      </h4>
      <hr />
      <ul className="plain-list">
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/forms/Assemblies%26Communities/GLSA+1+Foundations+of+Baha'i+Administrative+Order+(Updated+November+2024).pdf">
            Chapter 1 - Foundations of the Bahá’í Administrative Order
          </a>{' '}
          - <em>updated November 2024</em>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/forms/Assemblies%26Communities/GLSA+2+Baha'i+Elections+%26+LSA+Formation+(revised+February+2025).pdf">
            Chapter 2 - Bahá’í Elections
          </a>{' '}
          - <em>updated February 2025</em>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/forms/Assemblies%26Communities/GLSA+3+(NEW)+Responsibilities+of+the+Assembly+(November+2024).pdf">
            Chapter 3 - Responsibilities of the Assembly
          </a>{' '}
          - <em>updated November 2024</em>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/forms/Assemblies%26Communities/GLSA+4+(NEW)+Operating+Principles+and+Organization+of+the+LSA+(revised+January+2025).pdf">
            Chapter 4 - Operating Principles and Organization of the Local Assembly
          </a>{' '}
          - <em>updated January 2025</em>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/forms/Assemblies%26Communities/GLSA+5+Relationships+of+the+LSA+(revised+January+2025).pdf">
            Chapter 5 - Relationships of the Local Assembly
          </a>{' '}
          - <em>updated January 2025</em>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/forms/Assemblies%26Communities/GLSA+6+Community+Membership+(revised+January+2025).pdf">
            Chapter 6 - Community Membership
          </a>{' '}
          - <em>updated January 2025</em>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/forms/Assemblies%26Communities/GLSA+7+Feast+(Updated+November+2024).pdf">
            Chapter 7 - The Nineteen Day Feast
          </a>{' '}
          - <em>updated November 2024</em>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/forms/Assemblies%26Communities/GLSA+8+Holy+Days+(revised+January+2025).pdf">
            Chapter 8 – Holy Days
          </a>{' '}
          - <em>updated January 2025</em>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/forms/Assemblies%26Communities/GLSA+9+(NEW)+Protection+(November+2024).pdf">
            Chapter 9 - Protection
          </a>{' '}
          - <em>updated November 2024</em>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/forms/Assemblies%26Communities/GLSA+10+Upholding+Baha'i+Law+%26+Administering+Justice+(Updated+November+2024).pdf">
            Chapter 10 - Upholding Bahá’í Law and Administering Justice
          </a>{' '}
          - <em>updated November 2024</em>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/forms/Assemblies%26Communities/GLSA+11+(NEW)+Marriage+and+Family+Life+(January+2025).pdf">
            Chapter 11 - Marriage and Family Life
          </a>{' '}
          - <em>updated January 2025</em>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/forms/Assemblies%26Communities/GLSA+12+Divorce+(Updated+November+2024).pdf">
            Chapter 12 - Divorce - <em>updated November 2024</em>
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/forms/Assemblies%26Communities/GLSA+13+Burial+(Updated+October+2024).pdf">
            Chapter 13 - Burial
          </a>{' '}
          - <em>updated October 2024</em>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/forms/Assemblies%26Communities/GLSA+14+Other+LSA+issues+(Updated+November+2024).pdf">
            Chapter 14 - Other Issues Brought Before the Assembly
          </a>{' '}
          - <em>updated November 2024</em>
        </li>
      </ul>
      <hr />
      <h4 id="SupplementsGuideLine" className="anchor-heading">
        Supplements to <em>Guidelines for Local Spiritual Assemblies</em>
      </h4>
      <ul className="plain-list">
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/forms/Assemblies%26Communities/Archival+Records+for+Assemblies+(March%2C+2025).pdf">
            Archival Records for Assemblies – revised March 2025 (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/BIA-Security-5.pdf">
            Computer Security for Bahá’í Institutions (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/tab/forms/2021-confidentiality-LSA-docs.pdf">
            Confidentiality Policy and Form (English and Persian) (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/oad-glsa-0911_dvs.pdf">
            Domestic Violence Supplement to Guidelines (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/oad-glsa-0911_dvs_Persian.pdf">
            Domestic Violence Supplement to Guidelines (Persian) (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/oad-emergency-contact-info-sheet.pdf">
            Emergency Contact Information Sheet (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/eso/sc-2012-child-protection-form.pdf">
            Protection of Minors - Form(s) (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/Guiding-Principles-and-Standards-Pertaining-to-the-Protection-of-Minors-revised-January-2023.pdf">
            Protection of Minors – Guiding Principles – revised January 2023 (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/Policy+for+the+Protection+of+Children%2C+Junior+Youth%2C+and+Youth+-+July+2024.pdf">
            Protection of Minors – Policy – revised July 2024 (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/opa-annual-guide.pdf">
            Public Affairs Annual Opportunities Guide (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/Public-Affairs-Policies-Guidelines-2017.pdf">
            Public Affairs Policies and Guidelines (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/Resources+for+Local+Spiritual+Assemblies+rev+2024-06-25.docx">
            Resources for LSAs - revised June 2024 (DOCX)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/OAD_Some+thoughts+on+Assembly+operational+practices_v15_July+2024.pdf">
            Some Thoughts on Operational Practices of the LSA (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/tab/forms/Year+of+Waiting+and+Divorce+Flowchart+for+LSAs.pdf">
            Year of Waiting Flowchart for LSAs (PDF)
          </a>
        </li>
      </ul>
    </div>
  );
}

export default withRouter(withContext(MainGlsa));
