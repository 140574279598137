import React from 'react';

function Ridvan() {
  return (
    <div>
      <h1>Riḍván Election Materials 2025</h1>
      <p>
        <em>
          For questions about your community’s membership, OBS, eMembership2, or the formation forms, please contact the
          Membership Office at <a href="mailto:membership@usbnc.org">membership@usbnc.org</a>, or{' '}
          <a className="nowrap" href="tel:(847) 733-3445">
            (847) 733-3445
          </a>
          . For questions about election procedures or issues that may arise, please contact the Office of Community
          Administration at <a href="mailto:community@usbnc.org">community@usbnc.org</a> or{' '}
          <a className="nowrap" href="tel:(847) 733-3485">
            (847) 733-3485
          </a>
          .
        </em>
      </p>
      <p>
        Below are the support documents and forms for Local Spiritual Assemblies and Registered Groups for formation at Riḍván.
      </p>
      <p>To view any document, click on that document’s link. It will be displayed in Adobe® Reader™.</p>
      <p>
        To print a paper copy of any document, click on the printer icon in the Adobe® Reader™ toolbar once the document appears.
      </p>
      <p>
        You must have Adobe® Reader™ installed on your system to view and print these documents. If you don’t have this free
        software,{' '}
        <a target="_blank" rel="noopener noreferrer" href="http://get.adobe.com/reader/">
          click here
        </a>{' '}
        to download it.
      </p>
      <br />
      <h2>Local Spiritual Assemblies</h2>
      <h4>
        <strong>Educational Materials</strong>
      </h4>
      <ul>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=2t7G3skZbTg">
            Video: Summons to Service
          </a>
        </li>
        <li>
          Video: Bahá’í Electoral Process (
          <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=-eOVRW22D6I">
            Watch
          </a>
          ,{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.dropbox.com/s/bpwylkisl1fu218/Bah%C3%A1%E2%80%99%C3%AD%20Elections.mov?dl=0"
          >
            Download
          </a>
          )
        </li>
        <li>
          Video: A Model of Spiritually Based Elections (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.dropbox.com/s/g81q6a4i7j5g5vd/A%20Model%20of%20Spiritually%20Based%20Elections%202019.mov?dl=0"
          >
            Download
          </a>
          )
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/unit-convention/2024/Book+8.2+section+12+and+8.3+section+25+extracts+re+serving+on+institutions.pdf">
            Ruhi Book 8.2 section 12 and 8.3 section 25
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/unit-convention/2024/Book+10.2+sections+8-9+extracts+on+consultation.pdf">
            Ruhi Book 10.2 sections 8-9
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/Functions-of-the-Local-Spiritual-Assembly.pdf">
            Compilation on the “Functions of the Local Spiritual Assembly” (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/uhj-on-importance-of-elections.pdf">
            Importance of Bahá’í Elections – Letter from the Universal House of Justice dated March 25, 2007 (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/The-Nature-of-Bahai-Elections.pdf">
            The Nature of Bahá’í Elections – Quotations from the Writings of Shoghi Effendi (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2022/The+Nature+of+Bah%C3%A1%E2%80%99%C3%AD+Elections.docx">
            The Nature of Bahá’í Elections – Quotations from the Writings of Shoghi Effendi (MS Word)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/local-spiritual-assembly-compilation.pdf">
            The Local Spiritual Assembly – Compilation prepared by the Research Department of the Universal House of Justice (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2022/Purpose-and-Formation-of-the-Local-Spiritual-Assembly-compilation-Feb-2022.pdf">
            Purpose and Formation of the Local Spiritual Assembly – compilation (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2022/Purpose-and-Formation-of-the-Local-Spiritual-Assembly-compilation-Feb-2022.docx">
            Purpose and Formation of the Local Spiritual Assembly – compilation (MS Word)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/Purpose+and+Formation+of+the+Local+Spiritual+Assembly+-+compilation+(Persian).pdf">
            Purpose and Formation of the Local Spiritual Assembly – compilation (Persian) (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/Purpose+and+Formation+of+the+Local+Spiritual+Assembly+-+compilation+(Spanish).pdf">
            Purpose and Formation of the Local Spiritual Assembly – compilation (Spanish) (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/Purpose+and+Formation+of+the+Local+Spiritual+Assembly+-+compilation+(Swahili).pdf">
            Purpose and Formation of the Local Spiritual Assembly – compilation (Swahili) (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/Resources+for+Local+Spiritual+Assemblies+rev+2024-06-25.docx">
            Resources for LSAs (DOCX)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/Selected+Extracts+on+the+Administrative+Order+and+its+Evolution.pdf">
            Selected Extracts on the Administrative Order and its Evolution (PDF)
          </a>
        </li>
      </ul>
      <h4>
        <strong>Support Documents</strong>
      </h4>
      <ul>
        <li>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/Ridvan-Elections/2025/20250303+NSA+on+Preparation+for+2025+Ridvan+Election.pdf">
            National Spiritual Assembly on Preparation for 2025 Ridván Elections (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/Ridvan-Elections/2025/20250303+NSA+on+Preparation+for+2025+Ridvan+Election.docx">
            National Spiritual Assembly on Preparation for 2025 Ridván Elections (MS Word)
          </a>
        </li>
        <li>
          {/* <a href=""> */}
          National Spiritual Assembly Ridván 2025 Letter on Local Spiritual Assembly Elections (English) (PDF) - coming soon
          {/* </a> */}
        </li>
        <li>
          {/* <a href=""> */}
          National Spiritual Assembly Ridván 2025 Letter on Local Spiritual Assembly Elections (English) (MS Word) - coming soon
          {/* </a> */}
        </li>
        <li>
          {/* <a href=""> */}
          National Spiritual Assembly Ridván 2025 Letter on Local Spiritual Assembly Elections (Spanish) (PDF) - coming soon
          {/* </a> */}
        </li>
        <li>
          {/* <a href=""> */}
          National Spiritual Assembly Ridván 2025 Letter on Local Spiritual Assembly Elections (Spanish) (MS Word) - coming soon
          {/* </a> */}
        </li>
        <li>
          {/* <a href=""> */}
          National Spiritual Assembly Ridván 2025 Letter on Local Spiritual Assembly Elections (Persian) (PDF) - coming soon
          {/* </a> */}
        </li>
        <li>
          {/* <a href=""> */}
          National Spiritual Assembly Ridván 2025 Letter on Local Spiritual Assembly Elections (Swahili) (PDF) - coming soon
          {/* </a> */}
        </li>
        <li>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/Ridvan-Elections/2025/Timeline+for+implementing+2025+two-stage+elections.pdf">
            Implementing the Two-Stage Electoral Process 2025 (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/Ridvan-Elections/2025/1+Instructions+for+Assembly+Formation+2025.pdf">
            Instructions for Assembly Formation 2025 (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/Ridvan-Elections/2025/1+Instructions+for+Assembly+Formation+2025.docx">
            Instructions for Assembly Formation 2025 (MS Word)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/Ridvan-Elections/2025/2+Quick+Reference+Sheet+2025.pdf">
            Assembly Formation Quick Reference Sheet 2025 (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/Ridvan-Elections/2025/2+Quick+Reference+Sheet+2025.docx">
            Assembly Formation Quick Reference Sheet 2025 (MS Word)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/Ridvan-Elections/2025/3+Q+and+A+2025.pdf">
            Q &amp; A about Assembly Formation 2025 (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/Ridvan-Elections/2025/3+Q+and+A+2025.docx">
            Q &amp; A about Assembly Formation 2025 (MS Word)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/forms/Assemblies%26Communities/GLSA+2+Baha'i+Elections+%26+LSA+Formation+(revised+February+2025).pdf">
            <em>Guidelines for Local Spiritual Assemblies</em> – Chapter 2: Bahá’í Elections & LSA Formation (updated February
            2025) - PDF
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/persian-election-guidance.pdf">
            Bahá’í Elections – Guidance in Persian (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/Ridvan-Elections/2025/4+eMembership2+-+Assembly+Election+Guide+2025.pdf">
            eMembership2: LSA Election Guide 2025 (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/Ridvan-Elections/2025/4+eMembership2+-+Assembly+Election+Guide+2025.docx">
            eMembership2: LSA Election Guide 2025 (MS Word)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/Ridvan-Elections/2025/5+eMembership2+-+Helpful+Election+Tools+2025.pdf">
            eMembership2: Helpful Election Tools 2025 (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/Ridvan-Elections/2025/5+eMembership2+-+Helpful+Election+Tools+2025.docx">
            eMembership2: Helpful Election Tools 2025 (MS Word)
          </a>
        </li>
      </ul>
      <h4>
        <strong>Assembly Formation &amp; Election Forms</strong>
      </h4>
      <ul>
        <li>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/Ridvan-Elections/2025/LSA+Election+Report+Form+2025.pdf">
            Local Spiritual Assembly Election Report Form 2025 (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/Ridvan-Elections/2025/Joint+Declaration+2025.pdf">
            Local Spiritual Assembly Joint Declaration Report Form 2025 (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/Ridvan-Elections/2025/LSA+Officers'+Election+2025.pdf">
            Local Spiritual Assembly Officer Election Report Form 2025 (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/Ridvan-Elections/2025/By-Election+2025-2026.pdf">
            Local Spiritual Assembly By-Election Report Form 2025 (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/Ridvan-Elections/2025/LSA+Delegate+Election+2025.pdf">
            Local Spiritual Assembly Two-Stage Election - Delegate Election Report 2025 (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/Ridvan-Elections/2025/Summary+of+Local+Delegates+2025.pdf">
            Local Spiritual Assembly Two-Stage Election - Summary Delegate Report 2025 (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/Ridvan-Elections/2025/LSA+Election+by+Delegates+2025.pdf">
            Local Spiritual Assembly Two-Stage Election - Assembly Election Report 2025 (PDF)
          </a>
        </li>
      </ul>
      <h2>Registered Groups</h2>
      <h4>
        <strong>Support Documents</strong>
      </h4>
      <ul>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2024/Guidance+for+Groups+2024-02-27.pdf">
            Guidance for Bahá’í Groups (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/Ridvan-Elections/2025/6+eMembership2+-+Group+Registration+Guide+2025.pdf">
            eMembership2: Group Registration Guide 2025 (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/Ridvan-Elections/2025/6+eMembership2+-+Group+Registration+Guide+2025.docx">
            eMembership2: Group Registration Guide 2025 (MS Word)
          </a>
        </li>
      </ul>
      <h4>
        <strong>Group Registration Form</strong>
      </h4>
      <ul>
        <li>
          <a href="https://bahai-web.s3.us-east-1.amazonaws.com/Ridvan-Elections/2025/Group+Registration+2025-2026.pdf">
            Bahá’í Group Registration Form 2025 (PDF)
          </a>
        </li>
      </ul>
      <h4>
        <a target="_blank" rel="noopener noreferrer" href="https://helpcenter.obsbahai.org">
          Online Balloting System (OBS) Help
        </a>
      </h4>
    </div>
  );
}

export default Ridvan;
